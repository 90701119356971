/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Result } from './result';

/**
 * Last result for each result source (test, questionnaire, ...)
 */
export interface LastResults { 
    dadTest: Result;
    dadTestZ: Result;
    questionnaire: Result;
    game: Result;
    smart: Result;
    papQuestionnaire: Result;
}