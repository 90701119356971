export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './childAnnouncements.service';
import { ChildAnnouncementsService } from './childAnnouncements.service';
export * from './childCloseRelative.service';
import { ChildCloseRelativeService } from './childCloseRelative.service';
export * from './childDayoffs.service';
import { ChildDayoffsService } from './childDayoffs.service';
export * from './childExtendedData.service';
import { ChildExtendedDataService } from './childExtendedData.service';
export * from './childParentAttendance.service';
import { ChildParentAttendanceService } from './childParentAttendance.service';
export * from './childProfile.service';
import { ChildProfileService } from './childProfile.service';
export * from './childResults.service';
import { ChildResultsService } from './childResults.service';
export * from './children.service';
import { ChildrenService } from './children.service';
export * from './cities.service';
import { CitiesService } from './cities.service';
export * from './contracts.service';
import { ContractsService } from './contracts.service';
export * from './districts.service';
import { DistrictsService } from './districts.service';
export * from './eduActivities.service';
import { EduActivitiesService } from './eduActivities.service';
export * from './general.service';
import { GeneralService } from './general.service';
export * from './introspect.service';
import { IntrospectService } from './introspect.service';
export * from './materials.service';
import { MaterialsService } from './materials.service';
export * from './parents.service';
import { ParentsService } from './parents.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [CategoriesService, ChildAnnouncementsService, ChildCloseRelativeService, ChildDayoffsService, ChildExtendedDataService, ChildParentAttendanceService, ChildProfileService, ChildResultsService, ChildrenService, CitiesService, ContractsService, DistrictsService, EduActivitiesService, GeneralService, IntrospectService, MaterialsService, ParentsService, UsersService];
