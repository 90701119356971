<div class="wrap" *transloco="let t">
  <div class="page">
    <div class="page-main">
      <app-header [hidePremium]="hidePremium$ | async"></app-header>

      <app-sidebar appHoverEffectSidebar></app-sidebar>

      <div class="app-content main-content">
        <div class="side-app main-container">
          <router-outlet></router-outlet>
        </div>
      </div>

      <app-footer></app-footer>

      <ng-container *ngIf="(licenses$ | async)?.premium === false">
        <ng-container *ngIf="selectedChild$ | async">
          <div *ngIf="hidePremium$ | async" class="alert alert-warning sticky-footer text-center" role="alert" data-cy-test="noPremium">
            <span class="d-none d-sm-inline">{{
              isConnected ? t('shared/freeVersionConnected') : t('shared/freeVersionNotConnected')
            }}</span>
            <a
              [href]="
                isConnected
                  ? 'https://e-shop.isophi.cz/isophi-r-zvyhodnene?utm_source=erko-app&utm_medium=referral'
                  : 'https://e-shop.isophi.cz/isophi-r?utm_source=erko-app&utm_medium=referral'
              "
              target="_blank"
              class="btn btn-warning ms-2 ga-e-shop"
              (click)="trackingProductDetail(isConnected)"
              >{{ t('shared/purchaseLicense') }}</a
            >
            <a class="btn btn-warning d-none d-sm-inline-block ms-2" (click)="openActivateCodeDialog()">{{
              t('user/submitActivationCode')
            }}</a>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
