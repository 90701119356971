/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `dad_test` - Dad Test * `dad_test_z` - Dad Test Z * `questionnaire` - Questionnaire * `game` - Game * `smart` - Smart * `pap_questionnaire` - Pap Questionnaire
 */
export type SourceEnum = 'dad_test' | 'dad_test_z' | 'questionnaire' | 'game' | 'smart' | 'pap_questionnaire';

export const SourceEnum = {
    DadTest: 'dad_test' as SourceEnum,
    DadTestZ: 'dad_test_z' as SourceEnum,
    Questionnaire: 'questionnaire' as SourceEnum,
    Game: 'game' as SourceEnum,
    Smart: 'smart' as SourceEnum,
    PapQuestionnaire: 'pap_questionnaire' as SourceEnum
};